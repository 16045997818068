import React from "react";
import { useState } from "react";
import "./App.css";
import Nav from "./Nav";
import LogoHead from "./LogoHead";
import Footer from "./Footer";

function Recycle() {
  const [location, setLocation] = useState(null);
  return (
    <>
      <div className="bg-zinc-800 h-screen">
        <div className="pt-4">
          <LogoHead />
        </div>
        <Nav />
        <div className="text-xl mt-4 md:px-0 px-2 text-emerald-600 my-auto text-center">
          Next day collection on bookings before 6pm (Beginning January 2025!)
        </div>

        <div className="text-base text-zinc-400 mt-6 text-center mx-auto w-fit px-4 py-2 ">
          If you require a specific collection date please book the day before
          your desired date.
        </div>
        <div className="list-decimal  text-base text-zinc-300 mt-6 border rounded shadow-lg bg-zinc-700 mx-auto w-fit px-4 py-2 ">
          <li>Select your area</li>
          <li>Press Buy</li>
          <li>Fill in your details</li>
          <li>Place your tree outside the address you supplied</li>
          <li>
            Your tree will be collected the next day (if booked before 6pm)
          </li>
        </div>

        <ul className="list-disc"></ul>
        <div className="text-center mt-6">
          <div className="mt-4">
            <select
              onChange={(e) => setLocation(e.target.value)}
              className="p-2 text-center rounded"
              name="location"
              id="location"
            >
              <option selected disabled>
                Select your area
              </option>
              <option value="local">Greystones</option>
              <option value="local">Delgany</option>
              <option value="local">Kilcoole</option>
              <option value="non-local">Newtown</option>
              <option value="non-local">Newcastle</option>
              <option value="non-local">Bray</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
        <div className="text-center mt-4">
          {location === "local" ? (
            <div className="bg-zinc-800">
              <stripe-buy-button
                buy-button-id="buy_btn_1QNHCwHqouX7iLIyVqBnAJOD"
                publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
              ></stripe-buy-button>
            </div>
          ) : (
            <></>
          )}

          {location === "non-local" ? (
            <div className="bg-zinc-800">
              <stripe-buy-button
                buy-button-id="buy_btn_1QNHHIHqouX7iLIySVr8qWRa"
                publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
              ></stripe-buy-button>
            </div>
          ) : (
            <></>
          )}
          {location === "other" ? (
            <div className="text-white text-xl">Not available</div>
          ) : (
            <></>
          )}
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Recycle;
