import React, { useState } from "react";
import LogoHead from "./LogoHead";
import Nav from "./Nav";
import Footer from "./Footer";

function Buy() {
  const [item, setItem] = useState("trees");
  const treeValue = item === "trees" ? "underline" : "";
  const standValue = item === "stands" ? "underline" : "";
  const packageValue = item === "packages" ? "underline" : "";
  return (
    <div className="bg-zinc-800">
      <div className="pt-4">
        <LogoHead />
      </div>

      <Nav />
      <div>
        <div className="flex flex-wrap md:gap-12 gap-6 md:text-3xl text-2xl text-white justify-center">
          <button
            onClick={() => {
              setItem("trees");
            }}
            className={`my-10 text-emerald-500 ${treeValue}`}
          >
            Trees
          </button>

          <button
            onClick={() => {
              setItem("stands");
            }}
            className={`my-10 ${standValue}`}
          >
            Stands
          </button>
          {/*<button
            onClick={() => {
              setItem("packages");
            }}
            className={`my-10 text-orange-400 ${packageValue}`}
          >
            Packages
          </button>*/}
        </div>
        {item === "trees" ? (
          <div id="trees">
            <div className="flex flex-wrap mt-4 justify-center gap-4">
              <div id="40-euro-tree">
                <stripe-buy-button
                  buy-button-id="buy_btn_1O6ym3HqouX7iLIyEXTSL7rS"
                  publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                ></stripe-buy-button>
              </div>
              <div id="50-euro-tree">
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7Q0oHqouX7iLIytZeBJ8jp"
                  publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                ></stripe-buy-button>
              </div>
              <div id="60-euro-tree">
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7Q4lHqouX7iLIyhxtexvFj"
                  publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                ></stripe-buy-button>
              </div>
              <div id="70-euro-tree">
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7Q5jHqouX7iLIyfN9uLtfI"
                  publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                ></stripe-buy-button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {item === "stands" ? (
          <div id="stands">
            <div className="flex flex-wrap justify-center mt-10 gap-4">
              <div className="10-euro-stand">
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7Rg5HqouX7iLIy76XUrQxR"
                  publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                ></stripe-buy-button>
              </div>
              <div id="15-euro-stand">
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7RdtHqouX7iLIy6SIdlVgz"
                  publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                ></stripe-buy-button>
              </div>
              <div id="20-euro-stand">
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7PrpHqouX7iLIy3XYVKSwv"
                  publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                ></stripe-buy-button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {item === "packages" ? (
          <div id="packages">
            <div className="text-center text-zinc-200 my-6 text-xl md:text-2xl">
              with local collection (Greystones & Kilcoole){" "}
              <div className="text-sm font-light text-zinc-300">
                *beginning 3rd January
              </div>
            </div>
            <div className="tree-stand-local-collection flex flex-wrap justify-center gap-4">
              <div className=" text-zinc-200 border rounded-xl py-2 px-4 text-center bg-zinc-900">
                <div className="mt-4 text-2xl font-bold">
                  Standard Tree + <br />
                  Local Collection
                </div>
                <div className="mb-6 text-zinc-300">+ optional stand</div>
                <div className="text-center">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1O7QC2HqouX7iLIy6dwRq47U"
                    publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                  ></stripe-buy-button>
                </div>
              </div>
              <div className="large-tree-stand-local-collection text-zinc-200 border rounded-xl py-2 px-4 text-center bg-zinc-900">
                <div className="mt-4 text-2xl font-bold">
                  Large Tree + <br />
                  Local Collection
                </div>
                <div className="mb-6 text-zinc-300">+ optional stand</div>
                <div className="text-center">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1O7QbtHqouX7iLIyZofOGyY5"
                    publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                  ></stripe-buy-button>
                </div>
              </div>
            </div>
            <div className="text-center text-zinc-200 mb-6 mt-12 text-xl md:text-2xl">
              with non-local collection (Bray, Newtown, Newcastle){" "}
              <div className="text-sm font-light text-zinc-300">
                *beginning 3rd January
              </div>
            </div>
            <div className="standard-tree-stand-local-collection flex flex-wrap justify-center gap-4 mt-6">
              <div className="standard-tree-stand-non-local-collection text-zinc-200 border rounded-xl py-2 px-4 text-center bg-zinc-900">
                <div className="mt-4 text-2xl font-bold">
                  Standard Tree +<br />
                  Non-Local Collection
                </div>
                <div className="mb-6 text-zinc-300">+ optional stand</div>
                <div className="text-center">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1O7QNGHqouX7iLIyjGPk8Jci"
                    publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                  ></stripe-buy-button>
                </div>
              </div>
              <div className="standard-tree-stand-non-local-collection text-zinc-200 border rounded-xl py-2 px-4 text-center bg-zinc-900">
                <div className="mt-4 text-2xl font-bold">
                  Large Tree + <br /> Non-Local Collection
                </div>
                <div className="mb-6 text-zinc-300">+ optional stand</div>
                <div className="text-center">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1O7QqQHqouX7iLIy2KQpwVTQ"
                    publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                  ></stripe-buy-button>
                </div>
              </div>
            </div>
            <div className="text-center text-zinc-200 mb-6 mt-12 text-xl md:text-2xl">
              the more the merrier!{" "}
            </div>
            <div className="standard-tree-stand-local-collection flex flex-wrap justify-center gap-4 mt-6">
              <div className="standard-tree-stand-non-local-collection text-zinc-200 border rounded-xl py-2 px-4 text-center bg-zinc-900">
                <div className="mt-4 text-2xl font-bold">
                  Standard Tree + Pot Tree
                </div>
                <div className="mb-6 text-zinc-300">+ optional stand</div>
                <div className="text-center">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1O7Qt8HqouX7iLIyYamRx6s1"
                    publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                  ></stripe-buy-button>
                </div>
              </div>
              <div className="standard-tree-stand-non-local-collection text-zinc-200 border rounded-xl py-2 px-4 text-center bg-zinc-900">
                <div className="mt-4 text-2xl font-bold">
                  Large Tree + Pot Tree
                </div>
                <div className="mb-6 text-zinc-300">+ optional stand</div>
                <div className="text-center">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1O7Qx3HqouX7iLIyM5NZY5so"
                    publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                  ></stripe-buy-button>
                </div>
              </div>
            </div>
            <div className="text-center text-zinc-200 mb-6 mt-12 text-xl md:text-2xl">
              Special Offers!{" "}
            </div>
            <div className="text-center text-zinc-200">
              Use code{" "}
              <span className="text-orange-600 animate-pulse">
                BLTREESONLINE
              </span>{" "}
              for 5% off at checkout
              <div className="text-zinc-300 text-sm">
                *first 20 customers only
              </div>
            </div>
            <div className="standard-tree-stand-local-collection flex flex-wrap justify-center gap-4 mt-6">
              <div className="standard-tree-stand-non-local-collection text-zinc-200 border rounded-xl py-2 px-4 text-center bg-zinc-900">
                <div className="mt-4 text-2xl font-bold">
                  2 Standard Trees +<br /> Local Collection
                </div>
                <div className="mb-6 text-zinc-300">+ 2 stands</div>
                <div className="text-center">
                  <stripe-buy-button
                    buy-button-id="buy_btn_1O7RCTHqouX7iLIyR45Tn3Hq"
                    publishable-key="pk_live_51KFo58HqouX7iLIyu1ubUw1bd1Fn63HUAV749mhJSeHFAC5vbgByspcTd3tux5SlqPgoHdNy7xZhgijarqClzOIh00lcx3MCTY"
                  ></stripe-buy-button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Buy;
